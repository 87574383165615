.navigation__toggle {
    opacity: 0;
    display: none;
}

.navigation__toggle:checked~.navigation__menu-button>.navigation__menu-icon {
    transform: rotate(45deg);
    position: fixed;
}

.navigation__toggle:checked~.navigation__menu-button>.navigation__menu-icon::before {
    top: 0;
    transform: rotate(0);
    position: fixed;
}

.navigation__toggle:checked~.navigation__menu-button>.navigation__menu-icon::after {
    top: 0;
    transform: rotate(90deg);
    position: fixed;
}

.navigation__toggle:checked~.navigation__menu-button>.navigation__menu-button_color_white {
    background-color: #FFFBF5;
}

.navigation__toggle:checked~.navigation__menu-button_color_white>.navigation__menu-icon::before {
    background-color: #FFFBF5;
}

.navigation__toggle:checked~.navigation__menu-button_color_white>.navigation__menu-icon::after {
    background-color: #FFFBF5;
}

.navigation__toggle:checked~.navigation__menu-panel {
    visibility: visible;
    right: 0;
}

.navigation__toggle:checked~.navigation__background {
    visibility: visible;
    opacity: 0.3;
}

.navigation__logo {
    background-image: url(../../images/logo.png);
    width: 137px;
    height: 32px;
    background-size: cover;
    margin: 24px 0 0 14px;
}

.navigation {
    height: 44px;
    width: 44px;
}

.navigation__menu-button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 100%;
    cursor: pointer;
    z-index: 101;
}

.navigation__menu-button:hover {
    opacity: 0.7;
    transition: opacity 0.7s;
}

.navigation__menu-button>.navigation__menu-icon,
.navigation__menu-button>.navigation__menu-icon::before,
.navigation__menu-button>.navigation__menu-icon::after {
    display: block;
    position: absolute;
    width: 28px;
    height: 1px;
    background-color: #FFFBF5;
    ;
    transition-duration: .25s;
}

.navigation__menu-button_color_white>.navigation__menu-icon,
.navigation__menu-button_color_white>.navigation__menu-icon::before,
.navigation__menu-button_color_white>.navigation__menu-icon::after {
    background-color: #FFFFFF;
}

.navigation__menu-button>.navigation__menu-icon::before {
    content: '';
    top: -10px;
}

.navigation__menu-button>.navigation__menu-icon::after {
    content: '';
    top: 10px;
}

.navigation__menu-panel {
    display: flex;
    flex-direction: column;
    position: fixed;
    visibility: hidden;
    top: 0;
    right: -100%;
    width: 520px;
    height: 100%;
    margin: 0;
    background: #162030;
    transition-duration: .25s;
    z-index: 100;
}

.navigation__links-list {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 22px;
    list-style: none;
    margin: 159px 0 0 0;
    padding: 0;
}

.navigation__link {
    display: flex;

    line-height: 22px;
    text-decoration: none;
    transition-duration: .25s;
    padding-bottom: 4px;
    border-bottom: 2px solid transparent;

    font-family: 'Onest';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #FFFFFA;

}

.navigation__link:hover {
    opacity: 0.7;
    transition: opacity 0.7s;
}

.navigation__link_current {
    border-bottom: 2px solid #FFFFFA;
}

.navigation__background {
    position: fixed;
    top: 0;
    left: 0;
    background-color: #000000;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    transition: visibility 0s, opacity 0.4s linear;
}

.navigation__contact {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
}

.navigation__info-text {
    font-family: 'Onest';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #98F7FF;
    margin: 4px 0 0 0;
}

.navigation__info-text_bold {
    font-family: 'Onest';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: #FFFFFA;
    margin: 0;
    text-decoration: none;
}

@media screen and (min-width: 941px) {
    .navigation {
        display: none;
    }
}

@media screen and (max-width: 520px) {

    .navigation__menu-button>.navigation__menu-icon,
    .navigation__menu-button>.navigation__menu-icon::before,
    .navigation__menu-button>.navigation__menu-icon::after {
        width: 22px;
    }

    .navigation__menu-button>.navigation__menu-icon::before {
        top: -9px;
    }

    .navigation__menu-button>.navigation__menu-icon::after {
        top: 9px;
    }

    .navigation__menu-panel {
        width: 100%;
        height: 100%;
    }

    .navigation__links-list {
        gap: 40px;
        margin: 130px 0 0 0;
    }
}