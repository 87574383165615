.main {
  background: radial-gradient(269.92% 60.56% at 63.23% 46.61%, #224F9C 0%, #1E417D 12%, #1B3561 25%, #192B4B 40%, #17253B 56%, #162132 74%, #162030 100%);
}

.main__information_block {
  padding-bottom: 250px;
  max-width: 1200px;
  width: calc(100% - 120px*2);
  margin: 96px auto 0 auto;
  display: flex;
  flex-direction: column;
}

.main__title {
  font-family: 'Onest';
  font-style: normal;
  font-weight: 700;
  font-size: 100px;
  line-height: 96px;
  color: #FFFFFA;
  margin: 164px 0 0 0;
  z-index: 10;
}

.main__subtitle {
  font-family: 'Onest';
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 41px;
  color: #FFFFFA;
  max-width: 413px;
  margin: 12px 0 0 0;
  z-index: 10;
}

.main__image_group {
  position: relative;
}

.main__image {
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  -webkit-animation-name: fade;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-duration: 12s;
  animation-name: fade;
  animation-iteration-count: infinite;
  animation-duration: 12s;
  position: absolute;
  right: 0;
}

.main__image_1 {
  background-image: url('../../images/main_1.svg');
  width: 521px;
  height: 525px;
  top: 50px;
}

.main__image_2 {
  background-image: url('../../images/main_2.svg');
  width: 893px;
  height: 446px;
  animation-delay: -8s;
  -webkit-animation-delay: -8s;
  opacity: 0;
  top: 100px;
}

.main__image_3 {
  background-image: url('../../images/main_3.svg');
  width: 554px;
  height: 446px;
  animation-delay: -4s;
  -webkit-animation-delay: -4s;
  opacity: 0;
  top: 30px;
}

@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }

  20% {
    opacity: 1;
  }

  33% {
    opacity: 1;
  }

  53% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }

  20% {
    opacity: 1;
  }

  33% {
    opacity: 1;
  }

  53% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@media screen and (max-width: 940px) {
  .main__information_block {
    width: calc(100% - 40px*2);
  }  

  .main__title {
    font-size: 70px;
  }

  .main__subtitle {
    font-size: 30px;
  }  
}

@media screen and (max-width: 500px) {

  .main__information_block {
    padding-bottom: 56px;
    max-width: 292px;
    width: calc(100% - 14px*2);
    margin: 30px auto 0 auto;
    display: flex;
    flex-direction: column;
  }

  .main__title {
    font-size: 44px;
    line-height: 52px;
    margin: 24px 0 0 0;
  }

  .main__subtitle {
    font-size: 20px;
    line-height: 25px;
    color: #FFFFFA;
    max-width: 258px;
    margin: 16px 0 0 0;
  }

  .main__image_group {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .main__image-mobile-block {
    height: 266px;
  }

  .main__image_1 {
    width: 266px;
    height: 268px;
    background-size: cover;
    top: 0;
  }

  .main__image_2 {
    width: 292px;
    height: 146px;
    background-size: cover;
    top: 60px;
  }

  .main__image_3 {
    width: 227px;
    height: 182px;
    background-size: cover;
    top: 30px;
  }

  .main__image {
    left: auto;
    right: auto;
  }

}