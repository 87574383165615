.cases__block {
    max-width: 1200px;
    width: calc(100% - 120px*2);
    margin: 0 auto;
    padding-bottom: 75px;
}

.cases__title {
    font-family: 'Onest';
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 44px;
    color: #FFFFFA;
    margin: 96px 0 0 0;
}

.cases__information {
    display: flex;
    margin: 34px 0 0 0;
    gap: 70px;
}

.cases__information-img {
    height: 100%;
    max-width: 540px;
    width: calc(100% - 120px*2);
}

.cases__description-title {
    font-family: 'Onest';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-transform: uppercase;
    color: #FFFFFA;
    margin: 0;
    height: 28px;
}

.cases__description-paragraph {
    font-family: 'Onest';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #FFFFFA;
    margin: 24px 0 0 0;
    width: 590px;
    height: 192px;
}

.cases__switcher {
    border: 1px solid #98F7FF;
    border-radius: 24px;
    height: 48px;
    width: 183px;
    display: flex;
    gap: 34px;
    align-items: center;
    justify-content: center;
    margin: 52px 0 0 0;
}

.cases__switcher-arrow {
    width: 15px;
    height: 24px;
    cursor: pointer;
}

.cases__switcher-arrow:hover {
    opacity: 0.7;
    transition: opacity 0.5s;
}

.cases__switcher-left {
    background-image: url('../../images/button_left.svg');
}

.cases__switcher-right {
    background-image: url('../../images/button_right.svg');
}

.cases__switcher-separator {
    height: 20px;
    border-right: 1px solid rgba(152, 247, 255, 1);
}

@media screen and (max-width: 1200px) {

    .cases__information {
        flex-direction: column;
    }

    .cases__description-title {
        font-size: 24px;
    }

    .cases__description-paragraph {
        font-size: 20px;
    }


    .cases__title {
        margin: 40px 0 0 0;
    }
}

@media screen and (max-width: 940px) {

    .cases__block {
        width: calc(100% - 40px*2);
        padding-bottom: 10px;
    }

    .cases__description-paragraph {
        width: calc(100% - 40px*2);
        height: 300px;
    }

    .cases__description-title {
        height: 50px;
    }

}

@media screen and (max-width: 460px) {
    .cases__block {
        max-width: 292px;
        width: calc(100% - 14px*2);
        padding-bottom: 32px;
    }

    .cases__title {
        font-size: 28px;
        line-height: 28px;
        margin: 40px 0 0 0;
    }

    .cases__information {
        margin: 43px 0 0 0;
        gap: 70px;
    }

    .cases__information-img {
        height: 173px;
        width: 292px;
    }

    .cases__description-title {
        font-size: 20px;
        line-height: 24px;
        height: 48px;
    }

    .cases__description-paragraph {
        font-size: 18px;
        line-height: 24px;
        margin: 20px 0 0 0;
        width: 292px;
        height: 288px;
    }

    .cases__switcher {
        width: 292px;
        gap: 80px;
        margin: 32px 0 0 0;
    }
}