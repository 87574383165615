.footer__block {
    width: calc(100% - 120px*2);
    margin: 36px auto 0 auto;
    max-width: 1200px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
}

.footer__logo {
    background-image: url(../../images/logo.png);
    width: 171px;
    height: 40px;
}

.footer__year {
    font-family: 'Onest';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #FFFFFA;
    margin: 0;
}

@media screen and (max-width: 500px) {
    .footer__block {
        max-width: 292px;
        width: calc(100% - 14px*2);
        flex-direction: column;
        margin: 32px auto 0 auto;
        gap: 4px;
    }

    .footer__logo {
        width: 137px;
        height: 32px;
        background-size: cover;
    }

    .footer__year {
        font-size: 14px;
    }
}