.App {
  background-color: #162030;
  width: 100%;
  margin: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-bottom: 44px;
}

@media screen and (max-width: 410px) {
  .App {
    padding-bottom: 20px;
  }
}