@font-face {
    font-family: 'Onest';
    src: url(./fonts/Onest-Regular.woff) format('woff');
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: 'Onest';
    src: url(./fonts/Onest-Medium.woff) format('woff');
    font-weight: 500;
}

@font-face {
    font-family: 'Onest';
    src: url(./fonts/Onest-Bold.woff) format('woff');
    font-weight: 700;
}

