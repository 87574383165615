.about__block {
    max-width: 1200px;
    width: calc(100% - 120px*2);
    margin: 0 auto;
    padding-bottom: 100px;
}

.about__title {
    font-family: 'Onest';
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 28px;
    color: #FFFFFA;
    margin: 96px 0 0 0;
}

.about__description-paragraph {
    font-family: 'Onest';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #FFFFFA;
    margin: 40px 0 0 0;
    max-width: 690px;
}

.about__stack {
    display: flex;
    gap: 40px;
    margin: 52px 0 0 0;
}

.about__stack-element {
    font-family: 'Onest';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    height: 120px;
    color: #FFFFFA;
    border-right: 1px solid #C6C6C6;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-right: 40px;
}

.about__stack-element:last-child {
    border: 0;
}

.about__stack-element-title {
    font-family: 'Onest';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: #98F7FF;
    margin: 0;
}

@media screen and (max-width: 940px) {
    .about__block {
        width: calc(100% - 40px*2);
        padding-bottom: 20px;
    }

    .about__stack {
        flex-direction: column;
        gap: 0;
        margin: 32px 0 0 0;
        width: 240px;
    }

    .about__stack-element {
        font-size: 18px;
        line-height: 24px;
        border-right: 0;
        border-bottom: 1px solid #C6C6C6;
        gap: 0;
        padding-right: 0;
        justify-content: center;
    }
}

@media screen and (max-width: 460px) {
    .about__block {
        max-width: 292px;
        width: calc(100% - 14px*2);
        padding-bottom: 0;
    }

    .about__title {
        font-size: 28px;
        margin: 40px 0 0 0;
    }

    .about__description-paragraph {
        font-size: 18px;
        line-height: 24px;
        margin: 28px 0 0 0;
        max-width: 292px;
    }

    .about__stack {
        flex-direction: column;
        gap: 0;
        margin: 32px 0 0 0;
    }

    .about__stack-element {
        font-size: 18px;
        line-height: 24px;
        border-right: 0;
        border-bottom: 1px solid #C6C6C6;
        gap: 0;
        padding-right: 0;
        justify-content: center;
    }
}