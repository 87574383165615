.contacts__block {
    max-width: 1200px;
    width: calc(100% - 120px*2);
    margin: 96px auto 0 auto;
    padding-bottom: 46px;
    background: #185ED5;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contacts__title {
    font-family: 'Onest';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFA;
    margin: 50px 0 0 0;
}

.contacts__info {
    display: flex;
    gap: 226px;
    margin-top: 32px;
}

.contacts__info-column {
    text-align: center;
}

.contacts__info-text {
    font-family: 'Onest';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #98F7FF;
    display: flex;
    gap: 16px;
    margin-top: 20px;
}

.contacts__info-text_bold {
    font-weight: 700;
    font-size: 24px;
    color: #FFFFFA;
    margin: 0;
    text-decoration: none;
}

.contacts__title_break {
    display: none;
}

@media screen and (max-width: 1200px) {

    .contacts__info {
        gap: 100px;
    }

    .contacts__info-text {
        flex-direction: column;
        font-size: 14px;
        line-height: 16px;
        gap: 12px;
        margin-top: 4px;
    }

    .contacts__info-text_bold {
        font-size: 20px;
        line-height: 28px;
    }

}

@media screen and (max-width: 940px) {
    .contacts__block {
        width: calc(100% - 40px*2);
    }
}

@media screen and (max-width: 590px) {

    .contacts__info {
        gap: 40px;
    }
}

@media screen and (max-width: 460px) {
    .contacts__block {
        max-width: 292px;
        width: calc(100% - 14px*2);
        margin: 32px auto 0 auto;
        padding-bottom: 52px;
    }

    .contacts__info {
        flex-direction: column;
        gap: 25px;
        margin-top: 40px;
    }

    .contacts__title {
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        margin: 40px 0 0 0;
        text-align: left;
        width: 268px;
    }

    .contacts__info-column {
        display: flex;
        width: 268px;
        text-align: left;
        gap: 16px;
        margin: 0 12px;
    }

    .contacts__info-phone-icon {
        width: 64px;
    }

    .contacts__title_break {
        display: inline;
    }

}