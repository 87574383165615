.header {
    width: calc(100% - 120px*2);
    margin: 36px auto 0 auto;
    max-width: 1200px;
    display: flex;
    justify-content: space-between;
}

.header__logo {
    background-image: url(../../images/logo.png);
    width: 171px;
    height: 40px;
    margin: auto 0;
}

.header__links {
    display: flex;
    gap: 52px;
    align-items: center;
}

.header__link {
    font-family: 'Onest';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-decoration: none;
    color: #FFFFFA;
}

.header__link:hover {
    opacity: 0.7;
    transition: opacity 0.5s;
}

.header__contact-button {
    font-family: 'Onest';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    width: 202px;
    height: 52px;

    color: #FFFFFA;
    background: #185ED4;
    border-radius: 24px;
    border: none;
    cursor: pointer;
}

@media screen and (max-width: 940px) {

    .header {
        width: calc(100% - 40px*2);
    }

    .header__link {
        display: none;
    }

    .header__contact-button {
        display: none;
    }
}

@media screen and (max-width: 460px) {
    .header {
        width: calc(100% - 14px*2);
        margin: 18px auto 0 auto;
        max-width: 292px;
        display: flex;
        justify-content: space-between;
    }

    .header__logo {
        width: 137px;
        height: 32px;
        background-size: cover;
    }
    
}
